import App from 'next/app';
import Router from 'next/router';
import React from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import withRedux from 'next-redux-wrapper';
import { PersistGate } from 'redux-persist/integration/react';
import url from 'url';
import { startOfTomorrow, format } from 'date-fns';
import createStore from '../components/store';
import { appWithTranslation } from '../components/i18n';
import {
  SetToken,
  LeadLogin,
  VerifyMail,
  VerifyMailWithGuid,
  LoginBySecret,
  DeleteLoginSecret,
  IsServerOnline,
} from '../components/services/webAppEndPoint';
import { GlobalStyle } from '../components/Theme';

// Sentry.init({
//   dsn: 'https://a49682dc2def4e74a9adbd11bcd87be3@o215953.ingest.sentry.io/5462514',
//   environment: process.env.NODE_ENV,
//   maxBreadcrumbs: 50,
// });

const tagManagerArgs = {
  gtmId: 'GTM-PVPSH58',
};

const accessFreeSites = [
  '/',
  '/affiliate/user/login',
  '/password-reset',
  '/new-password',
  '/login-error',
  '/register',
  '/auth-register',
  '/impressum',
  '/impressum/',
  '/agb',
  '/agb/',
  '/faq',
  '/faq/',
  '/richtlinien',
  '/richtlinien/',
  '/datenschutz',
  '/datenschutz/',
  '/support',
  '/support/',
  '/maintenance',
  '/danke-seite',
  '/danke-seite/',
  '/authh',
  '/authh/',
  '/newsletter/unsubscribed',
  '/newsletter/unsubscribed/',
];

class webApp extends App {
  static async getInitialProps(ctx) {
    const {
      Component,
      ctx: { req, res },
    } = ctx;
    const cookie = req ? req.headers.cookie : null;
    let token = null;
    let isDeepLink = false;
    let pageProps = {};
    let leadInfo = null;
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    if (req?.query.cid) {
      res.cookie('clickId', req.query.cid, { maxAge: 1000 * 60 * 60 * 24, sameSite: true });
    }

    //email template fix #temporary
    if(req?.path.startsWith('/messages/conversation/') ){
      let convId = req.path.split("/");
      convId = convId[convId.length-1];
      convId = convId.split("?");
      convId = convId[0];
      if(convId){
        res.redirect(
          url.format({
            pathname: `/conversations`,
            query: {
              conversationId: convId
            },
          })
        );
        res.end();
        return {};
      }
    }

    // Check Backend, if response returns status 900 redirect to maintenance
    if (req?.path !== '/maintenance') {
      IsServerOnline()
        .then((rres) => {
          if (!rres) {
            res.redirect(
              url.format({
                pathname: '/maintenance',
              })
            );
            res.end();
            return {};
          }
        })
        .catch((err) => console.log(err));
    }

    // Server Side Login with secret & guid
    const { secret, guid, slug, from } = req?.query ?? {};
    // LeadApi User
    if (req?.path === '/affiliate/user/login') {
      const slugInfo = slug ? url.parse(slug, true) : null;
      res.redirect(
        url.format({
          pathname: slugInfo ? slugInfo.pathname : '/main',
          query: { ...(req?.query ?? {}), ...(slugInfo?.query ?? {}) },
        })
      );
      res.end();
      return {};
    }
    // App User
    if (secret && guid) {
      if (from === 'app') {
        try {
          const { accessToken, ...lead } = await LoginBySecret(guid, secret);
          token = accessToken;
          leadInfo = lead;
          isDeepLink = true;
          res.cookie('fromApp', true, {
            path: '/',
            expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
          });
        } catch (err) {
          console.log(err, 'err deeplink');
          res.redirect(
            url.format({
              pathname: '/',
            })
          );
          res.end();
          return {};
        }
      } else {
        try {
          const { accessToken, ...lead } = await LeadLogin(guid, secret);
          token = accessToken;
          leadInfo = lead;
        } catch (err) {
          console.log(err, 'err deeplink');
          res.redirect(
            url.format({
              pathname: '/',
            })
          );
          res.end();
          return {};
        }
      }
      res.cookie('Token', token, {
        path: '/',
        expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
      });
      res.cookie('StayLogged', true, {
        path: '/',
        expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
      });
      // email Verifizierung verzögern
      res.cookie('verification', new Date(), {
        path: '/',
        expires: startOfTomorrow(),
      });
      // sicherstellen das Profil abgerufen wird
      res.cookie('getProfile', true, {
        path: '/',
        expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
      });
    }

    // Email Verify login and redirect
    const { verify_hash: verifyCode, guid: verifyGuid } = req?.query ?? {};
    if (verifyCode && verifyGuid) {
      const { accessToken: verifiyToken } = await VerifyMailWithGuid({
        hash: verifyCode,
        guid: verifyGuid,
      });
      if (verifiyToken) {
        token = verifiyToken;
        res.cookie('Token', token, {
          path: '/',
          expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
        });
        res.cookie('StayLogged', true, {
          path: '/',
          expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
        });
        res.cookie('verification', new Date(), {
          path: '/',
          expires: startOfTomorrow(),
        });
        res.cookie('success', new Date(), {
          path: '/',
          expires: startOfTomorrow(),
        });
        res.cookie('getProfile', true, {
          path: '/',
          expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),
        });
        res.redirect(
          url.format({
            pathname: '/main',
          })
        );
        res.end();
        return {};
      }
    }

    if (cookie) {
      const cookieContainer = new Cookies(cookie);
      token = cookieContainer.get('Token');
    }
    // Route for backend to Check if Node Server is available
    if (req?.url === '/5e41509dd0724d0d9dc1f7638fbee70d') {
      res.status(200);
      res.send('online');
      res.end();
    }
    try {
      if (!token && !isDeepLink && req && !accessFreeSites.includes(req.path)) {
        if (res) {
          res.writeHead(302, { Location: '/' });
          res.end();
        } else {
          Router.push('/');
        }
      }
    } catch (error) {
      console.warn(error);
    }
    return {
      pageProps,
      leadInfo,
      token: leadInfo ? token : null,
      cookie: req ? req.headers.cookie : null,
    };
  }

  constructor(props) {
    super(props);
    const { cookie, token } = props;
    const tokenEnabler = new Cookies(cookie);
    SetToken(tokenEnabler.get('Token') || token);
  }

  // componentDidMount() {
  //   TagManager.initialize(tagManagerArgs);
  // }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store, cookie, leadInfo } = this.props;
    const cookies = new Cookies(cookie);

    return (
      <CookiesProvider cookies={cookies}>
        <Provider store={store}>
          <PersistGate persistor={store.persistor}>
            <GlobalStyle />
            <Component {...pageProps} leadInfo={leadInfo} />
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}

export default withRedux(createStore)(appWithTranslation(webApp));
